<template>
  <b-container class="main-container d-flex justify-content-center mt-5 mb-5">
    <b-row class="main-row">
      <b-col>
        <h2 v-if="$route.params.id">
          Edit: {{ business.name }} Profile
        </h2>
        <h2 v-else>
          Add a new client.
        </h2>
        <hr>
        <br>
        <b-form @submit="onSubmit">
          <b-form-group
            id="input-group-name"
            label="Company Name*"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="business.name"
              name="name"
              placeholder="Enter company name"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-status"
            label="Status*"
            label-for="input-status"
          >
            <b-form-select
              id="input-status"
              v-model="business.status"
              name="status"
              :options="statusOptions"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-lead-source"
            label="Lead Source*"
            label-for="input-lead-source"
          >
            <b-form-select
              id="input-lead-source"
              v-model="business.lead_source"
              name="lead_source"
              :options="leadOptions"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-advisor"
            label="SR*"
            label-for="input-advisor"
          >
            <b-form-select
              id="input-advisor"
              v-model="business.advisor"
              name="advisor"
              :options="advisorOptions"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-success-manager"
            label="SM*"
            label-for="input-success-manager"
          >
            <b-form-select
              id="input-success-manager"
              v-model="business.success_manager"
              name="success_manager"
              :options="successManagerOptions"
              required
            />
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-admins"
            label="Admin(s)*"
            label-for="input-admins"
          >
            <FormTags
              v-model="business.admins"
              :options="adminOptions"
              name="admins"
              search-label="Search admins"
              :required="true"
              button-text="Choose admins"
            >
              <template #item="{ option }">
                <b>{{ option.full_name }}</b><hr>email:<b>{{ option.email }}</b>
              </template>
            </FormTags>
          </b-form-group>
          <b-form-group
            v-if="isAdmin"
            id="input-group-users"
            label="Users"
            label-for="input-users"
          >
            <FormTags
              v-model="business.users"
              :options="userOptions"
              name="users"
              search-label="Search users"
              button-text="Choose users"
            >
              <template #item="{ option }">
                <b>{{ option.full_name }}</b><hr>email:<b>{{ option.email }}</b>
              </template>
            </FormTags>
          </b-form-group>
          <b-form-group
            id="input-group-oneliner"
            label="Short Description"
            label-for="input-oneliner"
          >
            <b-form-input
              id="input-oneliner"
              v-model="business.oneliner"
              name="oneliner"
              placeholder="Describe the company in one line"
            />
          </b-form-group>
          <b-form-group
            id="input-group-summary"
            label="Summary"
            label-for="input-summary"
          >
            <b-form-textarea
              id="summary"
              v-model="business.description"
              name="description"
              placeholder="Short summary about the company"
              rows="3"
              no-resize
            />
          </b-form-group>
          <b-form-group
            id="input-group-logo"
            label="Company Logo"
            label-for="input-logo"
          >
            <img
              v-if="business.logo"
              :src="business.logo"
              class="logo"
            >
            <b-form-file
              accept=".jpg, .png, .gif, .jpeg"
              name="logo"
              size="sm"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="uploadFile"
            />
          </b-form-group>
          <b-form-group
            id="input-group-website"
            label="Website*"
            label-for="input-website"
          >
            <b-form-input
              id="website"
              v-model="business.website"
              name="website"
              type="url"
              placeholder="Company website (url)"
              rows="3"
              no-resize
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-hq"
            label="HQ*"
            label-for="input-hq"
          >
            <b-form-select
              id="hq"
              v-model="business.headquarters"
              :options="countryOptions"
              rows="3"
              required
            />
          </b-form-group>
          <b-form-group
            id="input-group-previous-experiences"
            label="Previous Experience With External Recruiters"
            label-for="input-previous-experiences"
          >
            <FormTags
              v-model="business.previous_experiences"
              :options="previousExperienceOptions"
              name="previous_experiences"
              button-text="Choose experiences"
            />
          </b-form-group>
          <b-form-group
            id="input-group-hr-structures"
            label="HR Structures*"
            label-for="input-hr-structures"
          >
            <FormTags
              v-model="business.hr_structures"
              :options="hrStructureOptions"
              name="hr_structures"
              :required="true"
              button-text="Choose structures"
            />
          </b-form-group>
          <b-form-group
            id="input-group-industries"
            label="Industries*"
            label-for="input-industries"
          >
            <FormTags
              v-model="business.industries"
              :options="industryOptions"
              name="industries"
              search-label="Search industries"
              :required="true"
              button-text="Choose industries"
            />
          </b-form-group>

          <b-form-group
            id="input-group-employees"
            label="# of Employees*"
            label-for="input-employees"
          >
            <b-form-select
              id="input-employees"
              v-model="business.employees"
              name="employees"
              :options="employeesOptions"
              required
            />
          </b-form-group>
          <b-button
            class="mb-4"
            type="submit"
            variant="primary"
          >
            Submit
          </b-button>
          <b-modal
            id="ok-modal"
            class="m-auto"
            size="lg"
            :title="$route.params.id ? 'Client edited' : 'Hurray, client added!'"
            centered
          >
            <p class="my-4">
              What would you like to do next?
            </p>
            <template #modal-footer>
              <b-button
                size="sm"
                variant="primary-light"
                @click="addMoreClients"
              >
                Add More Clients
              </b-button>
              <b-button
                size="sm"
                variant="primary-light"
                to="/admin/business"
              >
                Go Back To All Clients
              </b-button>
              <b-button
                size="sm"
                variant="primary"
                @click="$router.push('/admin/project/create')"
              >
                Next: Create Project
              </b-button>
            </template>
          </b-modal>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import FormTags from '@/components/FormTags'
import { BUSINESS_STATUSES } from '@/helpers/statuses'

export default {
  name: 'BusinessForm',
  components: {
    FormTags,
  },
  data () {
    return {
      business: {
        admins: [],
        users: [],
        previous_experiences: [],
        industries: [],
        hr_structures: [],
      },
      countryOptions: [],
      previousExperienceOptions: [],
      hrStructureOptions: [],
      industryOptions: [],
      statusOptions: BUSINESS_STATUSES,
      leadOptions: [
        'Cold call (sales)',
        'Email/LinkedIn reach out (sales)',
        'Referral (paid)',
        'Referral (organic)',
        'Groups/forums (sales)',
        'Social media (organic)',
        'Search (organic)',
        'Ads (paid)',
        'Personal network/events (sales)',
        'Webinar (organic)',
        'Other',
      ],
      advisorOptions: [
        'Diana Solonenkova',
        'Ina Mononen',
        'Eero Veider',
        'Piia Sander',
      ],
      successManagerOptions: [
        'Diana Solonenkova',
        'Ina Mononen',
        'Eero Veider',
        'Piia Sander',
      ],
      employeesOptions: [
        '2-10',
        '11-50',
        '51-200',
        '201-500',
        '501-1000',
        '1001-5000',
        '5001-10000',
        '10000+',
      ],
      adminOptions: [],
      search: '',
      userOptions: [],
    }
  },
  computed: {
    isAdmin: function () {
      return ['admin', 'manager'].includes(this.$store.state.auth.user.role)
    },
  },
  mounted () {
    // Load in all the values for dropdowns
    if (this.isAdmin) {
      // Only admins can access list of users
      axios.get('/admin/v2/users')
        .then(({ data }) => {
          this.adminOptions = this.mapValues(data, false, 'full_name')
          this.userOptions = this.mapValues(data, false, 'full_name')
        })
    }

    axios.get('/public/v2/values/countries')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        temp.unshift({ value: null, text: 'Select country' })
        // Oke, that's going to be dirty hack
        // Buuut.. Basically, coz this field expects stuff like "Estonia" etc
        // And only field which requires it all is "hq"
        // But countries list returns UUIDs as value
        // Then we gonna map labels to values and be done with it
        // At least until we do full DB migration
        // Same thing is done in src/modules/business/views/BusinessEdit.vue
        temp = temp.map(v => {
          return {
            value: v.label,
            label: v.label,
            text: v.text,
          }
        })
        this.countryOptions = temp
      })
    axios.get('/public/v2/values/industries')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        this.industryOptions = temp
      })
    axios.get('/public/v2/values/hr_structures')
      .then(({ data }) => {
        this.hrStructureOptions = this.mapValues(data)
      })
    axios.get('/public/v2/values/previous_experiences')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => a.label.localeCompare(b.label))
        this.previousExperienceOptions = temp
      })

    if (this.$route.params.id) {
      if (this.isAdmin) {
        // Load in business itself
        axios.get(`/admin/v2/businesses/${this.$route.params.id}`)
          .then(({ data }) => {
            this.business = data
            this.$set(this.business, 'admins', data.admins.map(admin => admin.id))
            this.$set(this.business, 'users', data.users.map(user => user.id))
            this.$set(this.business, 'industries', data.industries.map(m => m.id))
            this.$set(this.business, 'previous_experiences', data.previous_experiences.map(m => m.id))
            this.$set(this.business, 'hr_structures', data.hr_structures.map(m => m.id))
          })
      } else {
        // Load some less data for non-admins
        axios.get(`/v2/businesses/${this.$route.params.id}`)
          .then(({ data }) => {
            this.business = data
            this.$set(this.business, 'industries', (data.industries || []).map(m => m.id))
            this.$set(this.business, 'previous_experiences', (data.previous_experiences || []).map(m => m.id))
            this.$set(this.business, 'hr_structures', (data.hr_structures || []).map(m => m.id))
          })
      }
    }
  },
  methods: {
    uploadFile (event) {
      const form = new FormData()
      form.set('file', event.target.files[0])
      axios.post('/v2/upload', form)
        .then(({ data }) => {
          this.$set(this.business, 'logo', data)
        })
    },
    mapValues (data, prepend = false, textField = 'label') {
      let dataArr = data.map(option => {
        option.value = option.id
        option.text = option[textField]
        return option
      })
      if (prepend) {
        dataArr = [
          { text: 'Select One', value: null },
          ...dataArr,
        ]
      }
      return dataArr
    },
    onSubmit (event) {
      event.preventDefault()
      // Company profile edit & create logic for admins/managers
      const sendData = { ...this.business }
      if (this.isAdmin) {
        if (this.$route.params.id) {
          axios.put(`/admin/v2/businesses/${this.$route.params.id}`, sendData)
            .then(() => this.$bvModal.show('ok-modal'))
        } else {
          axios.post('/admin/v2/businesses', sendData)
            .then(() => this.$bvModal.show('ok-modal'))
        }
      // Company profile edit for clients
      } else {
        axios.put(`/v2/businesses/${this.$route.params.id}`, sendData)
          .then(() => {
            this.$router.push(`/business/${this.$route.params.id}/profile`)
          })
      }
    },
    addMoreClients () {
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.main-container {
  .logo {
    max-width: 100px;
    max-height: 100px;
    margin: 1rem;
  }
  .main-row {
    width: 550px;
  }
}
</style>
